(function () {
    window.getUnsupportedBrowsers = function () {
        return [
            {
                name: 'ie',
                minVersion: '-1'
            },
            {
                name: 'opera',
                minVersion: '-1'
                // for specifying a browser of which no version is supported, set the minVersion to -1
            }
        ];
    };

    /**
     * Function extracted from https://github.com/DamonOehlman/detect-browser
     *
     * This was extracted as we need to load it ahead of time and the
     * only reason it uses requireJS is extract the function as a requireJS module
     */
    window.detectBrowser = function (userAgentString) {
        function createMatch(pair) {
            return pair.concat(pair[1].exec(userAgentString));
        }

        function isMatch(pair) {
            return !!pair[2];
        }

        var browsers = [
            ['edge', /Edge\/([0-9\._]+)/],
            ['chrome', /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/],
            ['crios', /CriOS\/([0-9\.]+)(:?\s|$)/],
            ['firefox', /Firefox\/([0-9\.]+)(?:\s|$)/],
            ['opera', /Opera\/([0-9\.]+)(?:\s|$)/],
            ['opera', /OPR\/([0-9\.]+)(:?\s|$)$/],
            ['ie', /Trident\/7\.0.*rv\:([0-9\.]+)\).*Gecko$/],
            ['ie', /MSIE\s([0-9\.]+);.*Trident\/[4-7].0/],
            ['ie', /MSIE\s(7\.0)/],
            ['bb10', /BB10;\sTouch.*Version\/([0-9\.]+)/],
            ['android', /Android\s([0-9\.]+)/],
            ['ios', /iPad.*Version\/([0-9\._]+)/],
            ['ios', /iPhone.*Version\/([0-9\._]+)/],
            ['safari', /Version\/([0-9\._]+).*Safari/]
        ];

        var mapped = [];
        for (var i = 0; i < browsers.length; i++) {
            browsers[i] = createMatch(browsers[i]);
            if (isMatch(browsers[i])) {
                mapped.push(browsers[i]);
            }
        }

        var match = mapped[0];
        var parts = match && match[3].split(/[._]/).slice(0, 3);

        while (parts && parts.length < 3) {
            parts.push('0');
        }

        // return the name and version
        return {
            name: match && match[0],
            version: parts && parts.join('.')
        };
    };

    window.isBrowserSupported = function (name, version) {
        var browserVersion = version ? parseFloat(version) : 0;

        var getBrowserFromList = function (name) {
            var unsupportedBrowser;
            var browserList = window.getUnsupportedBrowsers();

            for (var i = 0; i < browserList.length; i++) {
                var browser = browserList[i];
                if (browser.name === name) {
                    unsupportedBrowser = browser;
                }
            }

            return unsupportedBrowser;
        };

        var isBrowserOutdated = function (browser) {
            return browser ? browserVersion < parseFloat(browser.minVersion) : false;
        };

        var unsupportedBrowser = getBrowserFromList(name);
        var parsedMinVersion = unsupportedBrowser ? parseFloat(unsupportedBrowser.minVersion) : -1;
        var browserSupported = unsupportedBrowser
            ? parsedMinVersion !== -1 && browserVersion >= parsedMinVersion
            : true;

        return {
            isBrowserSupported: browserSupported,
            isOutdated: isBrowserOutdated(unsupportedBrowser)
        };
    };

    function checkUnsupportedBrowser() {
        if (window.location.href.indexOf('unsupported-browser') > -1) {
            return;
        }
        var browser = window.detectBrowser(navigator.userAgent);
        var browserSupport = window.isBrowserSupported(browser.name, browser.version);
        if (!browserSupport.isBrowserSupported || browserSupport.isOutdated) {
            var url = './unsupported-browser/unsupported-browser-page.html';
            window.location.href = url;
        }
    }
    try {
        document.addEventListener('DOMContentLoaded', checkUnsupportedBrowser, false);
    } catch (e) {
        window.attachEvent('onload', checkUnsupportedBrowser);
    }
})();
/**
 * Please note that the var keyword was used over that of the better let keyword as IE 8 and IE 9 does not
 * support the let keyword and will throw an error if used in this script.
 */
